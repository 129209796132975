import './Experience.css'
import Markdown from 'marked-react';
import _ from "underscore"
import {parseRadar, getAllTags, TechnologyThing} from './RadarParser'
import {get} from './Http'
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import rawText from './TagSoup'
import { Box, Stack, Typography } from '@mui/material';
import StrippedMarkdown from './StrippedMarkdown';


interface LabeledYearMonth{
    year:number
    month:number
    label?:string
}

interface CompanyThing {
    name:string
    url?:string
    description?:string
    roles:RoleThing[]
}
interface RoleThing {
    title:string
    dates:string
    summary?:ReactNode|string
    accomplishments:ReactNode[]
}

function map<T, R>(list:T[], fn:(i:T, idx?:number)=>R){
    var results = []
    for(var x=0;x<list.length;x++){
      results.push(fn(list[x], x));
    }
    return results;
}
  
function each<T>(list:T[], fn:(i:T)=>void){
    map(list, fn)
}
  
let padN = (value:any, n:number):string => {
    let text = "" + value
    if(text.length < n){
        return padN('0' + text, n)
    }else{
        return text
    }
}

function parseDate(text:string):LabeledYearMonth{
    console.log("Parsing ", text)
    if(text==="current"){
        let today = new Date()
        return {month:today.getMonth() + 1, year:today.getFullYear(), label:"Current"} 
    }else{
          const int = (text:string):number => {
            return parseInt(text.replace(/ /g, ""), 10)
          }
          var parts = text.split("-")
          
          return {
            year:int(parts[0]),
            month:int(parts[1])
          }
    }

}
  
function duration(start:LabeledYearMonth, end:LabeledYearMonth){
    var monthsOffset = end.month - start.month
    var yearsOffset = end.year - start.year
  
    var durationInMonths = (yearsOffset * 12) + monthsOffset
  
    var years = Math.floor(durationInMonths / 12)
    var months = durationInMonths % 12
    
    function pluralize(base:string, number:number){
      if(number > 1) return base + "s";
      return base;
    }
    var yearsPart = (years >0 ? years + " " + pluralize("year", years): "") 
    var monthsPart = (months > 0 ? months + " " + pluralize("month", months) : "")
  
    if(yearsPart && monthsPart) return yearsPart + ", " + monthsPart;
    else return yearsPart + monthsPart;
}

function formatDate(yearMonth:LabeledYearMonth):string{
    let date = padN(yearMonth.month, 2) + "/" + yearMonth.year
    if(yearMonth.label){
        return yearMonth.label 
    }else{
        return date
    }
}

let parseDateRange = (text:string):LabeledYearMonth[] => map(text.split("to"), (str)=>parseDate(str.trim()))

let DateSpan = (props:{children:string})=>{

    var text = props.children
    if(text){
        console.log("text is ", text)
        var dates = parseDateRange(text)
        var start = dates[0]
        var end = dates[1]
      
        return <>{formatDate(start) + " to " + formatDate(end) + " (" + duration(start, end) + ")"}</>
    }else{
        return <></>
    }
};


let RoleBand = (props:{compact:boolean, datas:RoleThing})=>{
    const {compact, datas} = props
    let {title, dates, summary} = datas
    let summaryFormatted = typeof summary === "string" ? <Markdown>{summary}</Markdown> : summary;

    var [start, end] = parseDateRange(dates)
    return (
        <div className="role">
            {(!compact) && <>

                <ThingWithDatesOnSide 
                    className="role-title" 
                    thing={title}
                    dates = {<>{formatDate(start) + " to " + formatDate(end)}</>}
                    style={{
                        marginTop:"20px"
                    }}
            />  
                {/* <div className="role-title">{title}</div>
                <div className="role-span"><DateSpan>{dates}</DateSpan></div> */}
            </>}
            <div className="role-summary ">{summaryFormatted}</div>
            <div className="role-summary ">
                <ul>
                {
                    datas.accomplishments.map(a=>{
                        return <li>{a}</li>
                    })    
                }
                </ul>
            </div>

        </div>);
}

interface Tenure{
    first:LabeledYearMonth
    last:LabeledYearMonth | undefined
}
let parseCompanyDuration = (company:CompanyThing):Tenure=>{
    let roles = company.roles


    let first:LabeledYearMonth|undefined = undefined
    let last:LabeledYearMonth|undefined = undefined

    _.each(roles.slice().reverse(), (role)=>{
        var dates = parseDateRange(role.dates)
        var start = dates[0]
        var end = dates[1]
        if(!first){
            first = start;
        }

        last = end;
    })

    return {
        first:first!!,
        last:last
    }
}



let descripeCompanyDuration = (company:CompanyThing)=>{
    const {first, last} = parseCompanyDuration(company)
    return <>{duration(first!!, last!!)}</>
}

const joinReact = (separator:(idx:number)=>ReactNode, items:ReactNode[]):ReactNode => {
    return <>
        {items.map((i, idx)=>{
            return <>
                {idx>0 && separator(idx)}
                {i}
            </>
        })}
    </>
}

const filterDefined = <T,>(items:(T|undefined)[]):T[] => {
    return items.flatMap(i=> i ? [i] : [])
}

const ThingWithDatesOnSide = (props:{thing:ReactNode, dates:ReactNode, className?:string, style?:CSSProperties})=>{
    const {thing, dates, className, style} = props
    return (
        <Grid container className={className} style={style}>
            <Grid xs={12} sm={6}>{thing}
            </Grid>
            <Grid xs={12} sm={6}style={{textAlign:"right"}}><span  className="company-duration">{dates}</span> </Grid>  
        </Grid>
    )
}

let CompanyBand = (props:{name:string, description:string, url:string|undefined, roles:RoleThing[]})=>{
    let {roles, url} = props
    const {first, last} = parseCompanyDuration(props)

    const singleRole = roles.length == 1 ? roles[0] : undefined

    const items = filterDefined([
        props.name,
        singleRole?.title && <span style={{    
            // fontSize: "1em",
            // textAlign: "left",
            // fontWeight: "bold",
        }}>{singleRole?.title}</span>
    ])

    const fullTitle = joinReact(()=>" - ", items)
    return (<>
    
        <div className="company-header">
            <ThingWithDatesOnSide 
                className="role-company" 
                thing={
                    fullTitle
                // <Stack>
                //     <Box>{fullTitle}</Box>
                //     {/* {url && <Box style={{fontSize:"12pt"}}><a href={url} target="_blank">{url}</a></Box>} */}
                // </Stack>
                }
                dates = {<>{formatDate(first)} - {last ? formatDate(last) : "present"}</>}
            />  
            {/* <div className="company-description  "><Markdown>{props.description}</Markdown></div> */}
        </div>
        <div className="company">



            <div className="company-roles">
                {_.map(roles, (role) => {
                    return [<RoleBand compact={singleRole ? true : false} datas={role} />]
                })}
            </div>

        </div>
        </>)
};

let companies:CompanyThing[] = [
//     {
//         name:"Holler",
//         description: `Holler is your safe, digital connection to the people in your real-world experiences.  It's a fundamentally new way to be in community with the people and things in your world: before, during and after an experience. 
              
// A proprietary 6-dimensional algorithm connects you to the communities that matter in your real life, everywhere you go, all throughout the day.  Blends aspects of social, messaging, A/R & web 3.0 technologies.

// Currently in early-access with select areas & communities.  General availability in late 2023. 
        
// Join now at [holler.fyi](https://holler.fyi).`,
//         roles:[
//             {
//                 title:"Founding Architect", 
//                 dates:"2020-01 to current", 
//                 summary:hollerRoleSummary,
//                 accomplishments:[
//                     "fdsfdsa fdjskf dsajf jkfldsa ",
//                     "fdsfdsa fdjskf dsajf jkfldsa ",
//                     "fdsfdsa fdjskf dsajf jkfldsa ",
//                     "fdsfdsa fdjskf dsajf jkfldsa ",
//                 ],
//             }
//     ]},
    {
        name:"AppFoo",
        description: `

AppFoo is a services & tooling offering for building and transforming product/engineering teams at Private Equity and Venture backed organizations
  - Management Training
  - Fractional CTO
  - Acquisition Due Diligence
  - Outcome-focused tooling

Speed, Alignment & Accountability for Your Product & Engineering Org!
    `,
    url:"https://appfoo.io",
        roles:[
            {
                title:"Principal, Consultant", 
                dates:"2019-11 to current", 
                summary:<StrippedMarkdown>{`
                    Engaged to bring executive-level operational expertise and product innovation capabilities to P.E., V.C. and early-stage organizations
                    `}</StrippedMarkdown>,
                accomplishments:[
                    "De-risked $500 million+ PE acquisitions through in-depth technical, process, structural & architectural due-diligence",
                    "Reduced expenses by up to 75% and increased development velocity by up to 700% for failing complex software projects by aligning updated system architecture & processes and in-sourcing with entry-level domestic talent",
                    "Removed product, market and technical risks in multiple industries by designing and implementing 1.0 products for the AI, Social, Financial, Marketing, Real Estate, and Aerospace sectors",
                    <>Streamlined the innovation process at organizations of all stages through the release of the <em>AppFoo</em> toolset for Product & Engineering Teams (Burn/Plan)</>
                    // "Advised local startups, Winning-Team, 2022 Conejo Valley Startup Weekend",
                ],}
    ]},
    {
        name:"Commission Junction",
        description: `Generating well over $20 Billion in annual client revenue, CJ is the
        world's largest online _Affiliate Marketing_ & networking platform. The _Affiliate Marketing_ channel drives
        15% of all e-commerce revenue in the US & EU, and is growing globally. At the core of CJ's $165 million
        business is the CJ Platform, handling 24/7, real-time, high-volume tracking, attribution and ad-serving for
        over 30,000 advertisers and publishers worldwide, as well as the associated business marketplace and
        analytical products offered at cj.com. CJ's customer base encompasses a wide array of verticals (e.g.
        finance, retail, travel) and promotional methods (e.g. content, deals/coupons, search, email, display). CJ
        was acquired by Alliance Data Systems in September 2014, and by Publicis Groupe in April 2019`,
        url:"https://www.cj.com",
        roles:[
            {
                title:"VP/Head of Engineering", 
                dates:"2015-11 to 2019-09", 
                summary:"Promoted to accelerate product innovation worldwide, bring predictability to the release cycle, and protect existing revenue streams from upstart competitors",
                accomplishments:[
                    `Brought a measured 500% improvement in the frequency of successful product launches coupled with 80%+ timeline predictability, enabling higher customer retention and new-customer sales`, 
                    "Reduced expenses and increased alignment of all business functions (sales, marketing, client-dev, support) by giving transparency, visibility and accoutablility into release plans & predictions through a standardized planning methodology, toolset and vernacular across all 11 product+engineering teams, worldwide",
                    "Removed the existential threat of Apple's \"Intelligent Tracking Prevention\" technology through the design of new product features and the development of a broader strategic plan for this emerging class of technology",
                    "Enabled CJ to withstand emerging EU privacy headwinds (GDPR), transforming a crisis into an opportunity to innovate & establish leadership in the industry, and paving the way for a eventual seamless response to the CCPA & beyond.", 
                    "Reduced customer time-to-first-dollar while simultaneously increasing competetive advantages and internal development velocity by bringing a scaled focus on 'Developer Experience' (UX for APIs & SDKs), the creation of DX standards, API registry, and streamlining the presentation and integration of new customer-facing APIs from different teams through tooling in the CJ developer portal",   
                    "Brought a modern & consistent user experience across all areas of the product by enabling reuse of visual components across all teams through the creation of CJ's 'VisualStack'",
                    "Increased CJ's ability to leverage high-demand UX/UI talent across all product teams & deepened collaboration between UX Designers & Engineers through the establishment of a system for UX/UI mockups",
                    "Enabled the aquisition of CJ by Publicis in 2019 through successful management of due-diligence processes leading-up to the sale"
                ],},
            {
                title:"Director of Engineering", 
                dates:"2015-02 to 2015-11", 
                summary:"Promoted to bring alignment to projects, teams & architecture across the engineering department",
                accomplishments:[
                    "Brought company-wide excitement and raised gut-level expectations for rapid product innovation by organizing the company-wide, cross-functional CJ hackathon & innovation sprint",
                    "Improved developer round-trip times by up to 500% and laid the foundation for faster future development by implementing a simplified, FP-oriented platform architecture focused on developer productivity",
                    "Achieved lower talent costs, accelerated hiring and reduced error rates in recruiting by standardizing the interview process, introducing \"speed-dating\" interviews for university candidate rounds, streamlining the recruiting pipeline, and elevating the engineering recruiting brand in the talent marketplace",
                ],},
            {
                title: "Engineering Manager & Lead Product Engineer", 
                dates:"2010-09 to 2015-01", 
                summary: "Hired to drive new-product innovation and build engineering expertise within the Company",
                accomplishments:[
                    // "I was the technical and architectural lead and line-manager for one of CJ's cross-functional engineering teams.",
                    "Increased department-wide product deployment frequency by over 9000% by moving from a monthly deployment cycle to a fully-automated, hourly build/test/deploy pipeline",
                    "Re-aligned the cultural direction of the engineering department to on a focus on speed-to-market, high-quality and reliability by personally selecting and training the majority of new engineering hires",
                    "Expanded CJ's market reach to include in-app marketing; designed, built & launched CJ's first mobile SDK: App Engagement (web->app tracking infrastructure, android & iOS SDKs, fuzzy device correlation)",
                    "Revamped & re-launched CJ's customer-facing developer product, including the development and release of developers.cj.com and related initiatives to simplify & accelerate customer integrations",
                    "Designed and launched a new revenue stream by repurposing existing systems to create the Partner Tracking Platform: a white-box, turn-key affiliate network solution",
                    "Widened the addressable market by successfully building and launching CJ's Deep Linking Tools & APIs, enabling non-traditional publishers through bookmarklets & backend APIs",
                    "Awarded the annual CJ Leadership award in 2012",
                ],}
    ]},
    {
        name:"Moss Computing, CMAX/Cleveland Inc",
        description:`Moss was a SAAS loan origination & servicing software provider based in
        Cleveland, TN. It was an acknowledged segment leader with respect to performance, usability, UI design, and
        accessibility. CMAX's SAAS product lines were acquired by Moss Computing Inc. In 2009, Moss's CheckMAX
        product line was acquired by Nicsysco, Inc`,
        roles:[
            {title:"President & Lead Engineer", dates:"2008-12 to 2010-08", 
                summary:`Charged with bringing needed leadership and execution on all aspects of the design & implementation of a radical new loan origination and servicing application, while still maintaining a legacy financial offering.`,
                accomplishments:[
                    "Reduced operational costs 90% by re-platforming backend services and transitioning from on-premises to cloud, virtualized & local co-located services",
                    "Opened a new primary revenue stream by successfully bringing \"Loan Grow\" to market & transitioned the majority of the existing customer base",
                    "Implemented a highly successful transition from on-premises to remote-work",
                    "100% retention of key personnel",
                ],},
            {title:"Head of SAAS Software Development", dates:"2004-11 to 2008-12", summary:`Hired to jump-start and rebuild the team behind a mature-but-struggling SAAS financial product.`,
            accomplishments:[
                "Achieved a successful 100% transition from a team of low-budget, expiring H1B workers to an elite set of hand-picked, unusually talented-new graduates",
                "Improved customer satisfaction, reduced support calls and retained revenue by successfully & incrementally bringing stablity and reliablility to a buggy and unstable core architecture",
                "Developed the next-generation \"Loan Grow\" platform & product offering from scratch",
            ],}
        ]
    },
    // {
    //     name:"Andromech",
    //     description:`Andromech was an "outsourced CTO" IT Services Firm, focused on small businesses. It offered a unique blend of custom software development, administration, and on-site support.`,
    //     roles:[
    //         {title:"Principal", dates:"2002-07 to 2004-11", summary:<div><p>Transitioned the business into an "outsourced CTO" IT Services Firm, focused on small businesses.
    //         This offering consisted of a unique blend of custom software development, administration, and
    //         on-site support. My role consisted of a combination of software engineering and system/network
    //         design/administration.</p>

    //     <p>Notable development projects include:
    //     </p>
    //     <ul>
    //         <li>a custom object database</li>
    //         <li>an EJB based CAD Revision control system</li>
    //         <li>a custom event-driven MVC web application framework built on servlets/JSP</li>
    //     </ul></div>},
    //         {title:"Consultant", dates:"2001-02 to 2002-06", summary:`Consulted with numerous companies on network setup & technology strategy. Designed, implemented and administered retail and commercial networks, POS systems and technology solutions.`},
           
    //     ]
    // }

]


let MinorCompany = (props:{name:string, description?:string, roles:RoleThing[]})=>{
    let {name, description, roles} = props
    let c:CompanyThing = {
        ... props,
        description: description ?? ""
    }
    return (
        <div className="company minor-company">
            <span className="role-company">{name}
                <span className='company-duration'>({descripeCompanyDuration(c)})</span></span>
            <div className="company-description"></div>
            <div className="company-roles">
                {_.map(roles, (role)=>{
                    let {title, dates, summary} = role;
                    return (<div className="role">
                    <div className="role-title">{title}</div>
                    <div className="role-span"><DateSpan>{dates}</DateSpan></div>
                    <div className="role-summary "><Markdown>{summary}</Markdown>
                    </div>
                </div>)
                })}
                
            </div>
        </div>)
}

let minorCompanies:CompanyThing[] = [
    // {name:"Mac Superstore", 
    //  roles:[{
    //      title:"Apple-Certified Technician", 
    //      dates:"2000-06 to 2001-02", 
    //      summary:`Mac Superstore was an Apple-Certified repair & retail center. At the time, all warranty repairs on Apple products were performed by certified repair centers such as this.`}]},

    // {name:"MBI ETS", 
    //  roles:[{
    //      title:"Apple/Mac Administrator", 
    //      dates:"1998-12 to 2000-05", 
    //      summary:`Responsible for maintaining Macintosh systems in education and design departments. Developed custom student dial-in scripts. Setup multi-platform Linux file server (SMB & AFP protocols).`}]},

    // {name:"Megahertz Avionics", 
    //  roles:[{
    //      title:"Apprentice Technician", 
    //      dates:"1998-05 to 1998-08", 
    //      summary:`Performed various shop duties, including pitot/static instrument certifications on light aircraft.`}]}
]

let TechTag = (props:{tech:TechnologyThing})=>{
    const {tech} = props
    const {url, notes, name, tags} = tech
    const [expanded, setExpanded] = useState(false);
    const hasExtras = (notes || url)

    const displayName = name + (hasExtras? " ⭐" : "")

    const toggle = ()=>{
        setExpanded(!expanded)
    }

    if(expanded && hasExtras){
        return <span className='tech-tag tech-tag-expanded'>
        <span  onClick = {toggle} className='tech-tag-expanded-header'>{displayName} {(tags && tags.length >0) ? "[" + tags.join(", ") + "]": ""}</span> 
        <div className='tech-tag-guts'>
            <div className='tech-notes'> {tech.notes}</div>    
            {url ? <a className='tech-url' href={url} target={"_" + name}>{url}</a> : ""}
        </div>
    </span>
    }else{
        return <span onClick = {toggle} className='tech-tag tech-tag-collapsed'>{displayName}</span>
    }
}

let Buzzwords = (props:{})=>{
    let radar = parseRadar(rawText)
    console.log(JSON.stringify(radar, null, 4))

    return <div>
        <h3 className='tech-category-header'>Cross-Cutting Facets</h3>
        {getAllTags(radar).map(tag=>{
            return <span className='tech-tag tech-tag-collapsed'>
            {tag}
        </span>
        })}
        {_.map(_.keys(radar), (category)=>{
            console.log(category)
            let matches:TechnologyThing[] = radar[category]
            return <div className="tech-category-section">
                <h3 className='tech-category-header'>{category}</h3>
                {_.map(_.sortBy(matches, "name"), (tech)=>{
                    return <TechTag tech={tech}/>
                })}
            </div>
        })}
    </div>
}

const compare = (a:LabeledYearMonth, b:LabeledYearMonth):number => {
    const format = (d:LabeledYearMonth) => (d.year * 12) + d.month
    const af = format(a)
    const bf = format(b)

    return af - bf
}

const thisMonth:LabeledYearMonth = {
    year:new Date().getFullYear(),
    month:new Date().getMonth()
}
const firsts = companies.concat(minorCompanies).map((c) => parseCompanyDuration(c).first).sort(compare)
const lasts = companies.concat(minorCompanies).map((c) => parseCompanyDuration(c).last ?? thisMonth).sort(compare)

console.log("firsts", firsts)
console.log("lasts", lasts)

const first = firsts[0]
const last = lasts[lasts.length -1]

const numYears = lasts[lasts.length -1].year - firsts[0].year
console.log(numYears, "Career", first, last)

let body = (<div>
        <div className="contact-band">
        <a href="tel:1-661-332-4407">1-661-332-4407</a> | <a href="mailto:stu@penrose.us?subject=found you on stu.penrose.us">stu@penrose.us</a> | <a href="https://www.linkedin.com/in/stu-penrose">linkedin.com/in/stu-penrose</a>
        </div>

        <div className="location-band">
        Greater Los Angeles Area
        </div>


        <div >
            {/* <StrippedMardown>{`
                ## Areas of Expertise 

                -  Focuses on developer experience repackaging and revamping documentation and onboarding to accelerate adoption and boost revenue
                -  Changes product engineering giving more visibility, accountability, and predictability to boost efficiency and accelerate profit capture
                -  Gets around technical roadblocks with product strategy to become more relevant with changing tech landscape bolstering growth and value of the company
                -  Implements deployment cycle changes that speed out new feature with zero downtime
            `}</StrippedMardown> */}
            {/* <StrippedMardown>{`
                ## Areas of Expertise 
                - Accelerated Development Cycle  
                - Total Product Agility & Prediction 
                - Integrated Technical Product Strategy
                - Customer Development Experience 
            `}</StrippedMardown> */}
            <StrippedMarkdown>{`
                ## Areas of Expertise 
                - Product/Engineering Cycle Acceleration
                - Roadmap Agility & Release Prediction 
                - Integrated Technical Product Strategy
                - Customer Experience Revamp (UIs, APIs, SDKs), Faster Time-to-First-Dollar
                - Cross-Functional Design & Implementation of Complex Software + Hardware Products
            `}</StrippedMarkdown>
        </div>

        {_.map(companies, (company)=><CompanyBand name = {company.name} roles = {company.roles} description = {company.description ?? ""} url={company.url}></CompanyBand>)}

        {_.map(minorCompanies, (mc)=><MinorCompany name = {mc.name} roles = {mc.roles}/>)}

    <div className="references-notice" >References available upon request</div>

    <h1 className='tech-header'>AppendixA: Open Source & Community Initiatives</h1>
<h3 className='tech-category-header'>Community Organizations</h3>
<div className='appendix-list'>
    <Markdown>{`
- [Brain Stormz Robotics](http://brainstormz.us) (head coach)
- [CodeCraft meetup](https://www.meetup.com/codecraftgroup/?_cookie-check=TnWq5FT19dF6_sPG) (founding organizer)
`}</Markdown>
</div>

<h3 className='tech-category-header'>Open-Source Initiatives</h3>
I have been the primary author of multiple open-source initiatives.  Here are a few:
<div className='appendix-list'>
<Markdown>{`
- [HttpObjects](http://httpobjects.org) (an http abstraction layer used to power billions of dollars of online transactions at multiple companies)
- [Etherboard](https://github.com/cjdev/etherboard) (an online collaboration tool)
- [TeamStory](https://github.com/cjdev/teamstory) (a tool for storing & communicating agile backlogs)
- [JSHint Mojo](https://github.com/cjdev/jshint-mojo) (the #1 jshint maven module)
- [Protocop](https://github.com/cjdev/protocop) (a tool for enforcing object protocols & function contracts in javascript)
- [Scm-Conduit](https://github.com/cjdev/scm-conduit) – a daemon for bridging decentralized build systems (git, bzr) to perforce
- [Paqman](https://github.com/cjdev/paqman) – a learning & proficiency management tool`}</Markdown>
</div>

    <h1 className='tech-header'>AppendixB: Direct Technology Experience</h1>
    <Buzzwords/>


</div>)

const name = window.location.search.indexOf("print")!=-1 ? "Stu Penrose" : "experience"

export default {
    "name":name,
    "subtitle":``, //`Highlights from over ${numYears} years of innovation in creating software and building teams`,
    body:body
};