import Markdown from 'marked-react'
import experience from './Experience'
import { ReactNode } from 'react'

export type Content = ReactNode | string | undefined | ReactNode[]

export interface PageStuff {
  title?:string
  parent?: PageStuff
  name?: string
  subtitle: Content
  body: ReactNode | undefined
  footer?: Content
  displayName?: () => string
}

let design: PageStuff = {
  "name": "design",
  "subtitle": "Thoughts about code, design, architecture, craftsmanship",
  body: <div>
    <h2>Design Essays</h2>
    Some (slightly cheeky) essays on aspects of software design & problem solving:
    <ul>
      <li><a href="date-and-time">Date and Time</a></li>
      <li><a href="reinventing-the-wheel">Re-Inventing the Wheel</a></li>
    </ul>


  </div>
}


let agility: PageStuff = {
  "name": "agility",
  "subtitle": "Thoughts on the organization, alignment & operation of product-focused organizations",
  body: <div>
    <h2>Structure, Architecture & Process</h2>
    A product-driven company requires all functions to be in alignment, and these elements are especially important:
    <ul>
      <li><a href="planning">The Planning Process</a></li>
      <li><a href="teams-and-architecture">Teams and architecture</a></li>
      <li><a href="process">Quality & Velocity</a></li>
    </ul>

  </div>
}

let timeCostScopeQuality: PageStuff = {
  "name": "time-cost-scope-quality",
  "subtitle": "you're thinking of it wrong",
  body: <div>
    <img style={{ width: "100%", "marginTop": "40px" }} src="time-scope-cost-quality.png" />
  </div>
}

let leadership: PageStuff = {
  "name": "leadership",
  "subtitle": "Thoughts on leadership & management",
  body: <div>
    <h2>Leadership Essays</h2>
    Some advice for leaders:
    <ul>
      <li><a href="actually-lead">A note for folks who struggle with leading</a></li>
    </ul>
  </div>
}

let home: PageStuff = {

  "name": "stu penrose",
  "subtitle": [<span className="title-tags-fragment">Leader, Innovator, Strategic Thinker, Engineer</span>],
  body: <div>

    <p>This is the home page of Stu Penrose.</p>


    <p>I'm a proven leader & transformer of product innovation & delivery organizations in the context of high-stakes, high-volume, real-time, user-facing platforms.</p>

    <p>I specialize in creating technology organizations & architectures where innovation & product delivery scales well.</p>

    <p>Currently:
      <ul>
        <li>Consulting/Fractional CTO for <a href="https://appfoo.io" target="_blank">Private Equity, Venture Capital, Reviving and Early-Stage Organizations</a></li>
        <li>Head Coach of <a href="https://brainstormz.us" target="_blank">BrainStormz Robotics</a></li>
      </ul>
    </p>

  </div>,
  footer: <div><div className="footer" >
    <div className="contact-band">
      <a href="tel:1-661-332-4407">1-661-332-4407</a> | <a href="mailto:stu@penrose.us?subject=found you on stu.penrose.us">stu@penrose.us</a> | <a href="http://stu.penrose.us">stu.penrose.us</a>
    </div>
    <a target="_blank" href="https://www.linkedin.com/in/stu-penrose/"><img src="linkedin-logos/2-Color/Logo-2C-81px-R.png" /></a>
    <a target="_blank" href="https://github.com/stupenrose"><img src="github-logos/GitHub_Logo.png"></img></a>
    <a target="_blank" href="https://www.meetup.com/members/32495172/"><img src="meetup-logo.svg"></img></a>
  </div>
  </div>
}


let process = {
  parent: agility,
  "name": "stay speedy",
  "subtitle": "Thoughts about process & teams in software/product development.",
  body: <div>

    <h2>Quality, Velocity & Technical Process</h2>

    <p>"Agile" processes can produce short-term increases in velocity, but true long-term agility & velocity only comes when these processes are paired with the right technical practices & architectural strategy.  </p>

    <h3>Related Links</h3>

    <ul>
      <li>The <a href="http://manifesto.softwarecraftsmanship.org/">Software Craftsmanship Manifesto</a></li>
      <li><a href="http://www.extremeprogramming.org/rules.html">Extreme Programming</a></li>
    </ul>

  </div>
}

let teamsAndArchitecture: PageStuff = {
  parent: agility,
  "name": "architec|t|eam",
  "subtitle": "",
  body: <div>

    <h2>Teams & Architecture</h2>

    <p>Much of my professional focus has been on achieving good product innovation & delivery velocity at scale.  This requires skillfull alignment of 2 main dimensions:</p>
    <ol>
      <li>team structure (i.e. your "people architecture")</li>
      <li>system architecture</li>
    </ol>

    <p>Good alignment of these dimensions is key the key to enabling the accountability, autonomy and engagement that is needed to truly innovate at scale.</p>

    <h3>Related Links</h3>
    <ul>
      <li>The <a href="https://www.agilemanifesto.org/">Agile Manifesto</a></li>
      <li><a href="https://svpg.com/product-vs-feature-teams/">Product vs Feature Teams</a></li>
      <li>The "Spotify Model"
        <ul>
          <li><a href="http://blog.crisp.se/wp-content/uploads/2012/11/SpotifyScaling.pdf">Scaling Spotify (2012)</a></li>
          <li>"Spotify Engineering Culture" (<a href="https://labs.spotify.com/2014/03/27/spotify-engineering-culture-part-1">part 1</a>, <a href="https://labs.spotify.com/2014/09/20/spotify-engineering-culture-part-2/">part 2</a>)</li>
          <li><a href="http://blog.crisp.se/2015/06/07/henrikkniberg/no-i-didnt-invent-the-spotify-model">I didn't invent the spotify model</a> - Henrik Kniberg</li>
        </ul>
      </li>
    </ul>


  </div>
}

let nurture: PageStuff = {
  name: "nurture",
  subtitle: "Growth for People and Organizations",
  body: <div><Markdown openLinksInNewTab={false}>{`

## Training

Some things I can help you with:

- _CEOs & Leaders_: I can help you achieve [speed, alignment & accountability](https://appfoo.io)
- _Beginners_: I can help you [become a software engineer](/getting-started)

## Talks

Occasionally, I find the time to speak publicly; you can find some of those presentations [here](/talks).

`}</Markdown>

  </div>
}

let gettingStarted: PageStuff = {
  parent: nurture,
  name: "How to Start",
  subtitle: "How do I become a software engineer?",
  body: <div>

    <h2>Becoming a Software Engineer</h2>

    <p>One of the most frequently asked questions I get concerns how to start or grow a career as a software engineer.  This is my standard advice:</p>

    <h4>You don't need a formal education</h4>

    <p>It doesn't take a 4-year degree or a formal education to have a well-paid and satisfying career as a software engineer.  These days, even the largest, most high-profile internet companies are hiring software engineers that don't have degrees.</p>

    <h4>You don't need a coding bootcamp</h4>
    <p>Coding bootcamps are a great way to get started, and are far more cost-effective than a 4 year degree.  However, there is an even cheaper, more cost effective path.</p>

    <h4>The Direct Path</h4>

    To follow this path, you must:

    <ul>
      <li>be self-motivated</li>
      <li>know how to learn</li>
      <li>create time to learn</li>
      <li>have a computer and an internet connection</li>
      <li>choose a project to work on</li>
      <li>have a mentor</li>
      <li>hang-out with software engineers</li>
    </ul>


    For more on this, see <a href="direct-to-engineer">The Direct Path to Becoming a Software Engineer</a>.
  </div>
}


let talks: PageStuff = {
  parent: nurture,
  "name": "Talks",
  "subtitle": "Occasionally, I find the time to speak publicly; here are some of those presentations.",
  body: <div>
    <h2>Javascript TDD</h2>
    <h3><a href="./talks-data/2014-07-30-js-tdd">slides</a></h3>
    <iframe className="video" src="https://www.youtube.com/embed/QJ0lJAHaWC8" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>


    <h2>Protocop</h2>
    <h3><a href="./talks-data/2014-11-03-protocop">slides</a></h3>
    <div className="novideo">no video available</div>

    <h2>How to Write A Bad Wiki Page</h2>
    <iframe className="video" src="https://www.youtube.com/embed/9gp3MXGsdJA" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
  </div>
}

let reinventingTheWheel: PageStuff = {
  parent: design,
  "name": "[re]invention",
  "subtitle": "Should you \"reinvent the wheel\"?",
  body: <div>
    <h2>The most reinvented concept on the planet</h2>
    <p>It's hard to imagine a more reproduced concept than the wheel.</p>
    <p>A few examples:

      <ul>
        <li>Ford F150 wheels</li>
        <li>wagon wheels</li>
        <li>skateboard wheels</li>
        <li>chair wheels</li>
        <li>bicycle wheels</li>
        <li>luggage wheels</li>
      </ul>

      In each case, it is obvious that "reinventing the wheel" was the right design decision;  "reinventing the wheel" is a necessary part of good and purposeful design.

    </p>

    <h2>Different problems ...</h2>

    ... are not the same.  If they were the same, they wouldn't be different.


    <h2>Think for yourself</h2>
    <p>

    </p>

    <p>Fear of "reinventing the wheel" is a first step toward bad design.  It leads to:</p>

    <ul>
      <li>choosing a solution before you understand what the problem is</li>
      <li>trusting others to solve your problems for you</li>
      <li>assuming that your problem is the same as others'</li>
    </ul>

    <h2>Should I really?</h2>
    <p>Quite possibly; it may be the most responsible thing to do.</p>

  </div>
}


let dateAndTime = {
  parent: design,
  "name": "Date and Time",
  "subtitle": "Think Clearly About Dates and Times",
  body: <div>

    <h2>Choose a time-scale</h2>
    <p>
      Common ones:
      <ul>
        <li>machine
          <ul>
            <li>unix timestamps</li>
          </ul>
        </li>
        <li>human
          <ul>
            <li>gregorian calendar</li>
          </ul>
        </li>
      </ul>
    </p>

    <h2>Choose a data-type</h2>
    <p>A 'date' is not a time-stamp, is not a number of milliseconds</p>
    <h2>Stop!</h2>

    <ul>
      <li>Using points on one timescale to model ranges on another</li>
      <li>Thinking that "UTC" will solve your modelling problems</li>
    </ul>

    <h2>Your language/database's date/time data-types ...</h2>
    <p>...are broken; don't use them.</p>

    <h2>UTC ...</h2>
    <p> ... is not a time-scale.</p>

  </div>
}

let planning: PageStuff = {
  parent: agility,
  subtitle: <span className="banner-note">I do consulting on this topic!  See <a target="_appfoo" href="https://appfoo.io">appfoo.io</a> to learn more.</span>,
  body: <div>
    <Markdown>{`
## Predictability, Accountability, Alignment

Product ideation, design & delivery is a highly complex & creative endeavor.  But, it can be aligned and brought into harmony with your business realities & broader organization.

To get there requires changes in 3 key areas:
  - **mindset** - training your team to think in terms of releases that are focused on business outcomes
  - **practices** - plan, work & adapt in an outcome-focused way
  - **toolset** - implementation of an outcome-focused toolset

## A Proven Framework

This the framework I developed at [CJ.com](https://www.cj.com) and have applied successfully elsewhere.

### Teams own their plans & continuously update them

  - Each team works together (incl. product & engineering roles) to **create an initial plan**, then **continuously updates it** as part of their regular development iteration cycle.
  - The plan is the set of user/business-facing releases, each of which achieve some business outcome.  
    - _NOTE: The plan includes what is often called the 'backlog' and the 'roadmap'._
  - The plan is the plan for how the team's energies will be used, so each team has one (and only one) plan
  - It typically includes the current project, maintenance work from prior projects, and upcoming projects
  - __It's a team plan, not a project plan__

### The team's engineers do the estimates

  - Engineers do the estimates _(the actual, specific engineers who will do the work)_
  - Not other engineers
  - Not their manager
  - Not product people
  - Not executives
  - Nobody else

### The plans are data

Plans should be stored in such a way that anyone in the org. can easliy:

  - See the current plan (~ real-time) 
  - See what it used to be
  - See date+time projections based on current knowns
  - See what's changed since the last time they saw it (releases & projections) 

Storing the entire history of each iteration of the plan as data allows static-analysis on the plan - helping teams and managers avoid common planning pitfalls such as 
  - inadvertently missing estimates
  - stale estimates
  - getting too granular, too soon
  - staying too broad, too late

_WARNING!_:
  - Don't use multiple planning/backlog/roadmap/ticketing systems (it's all one thing)
  - Don't use multiple teams per plan, or multiple plans per team
  - Don't overcomplicate it

### The whole organization [regularly] scrutinizes the plan

Product plans must be highly visible and regularly (e.g. monthly) reviewed with all major business functions, ideally at the same time, in the same meeting.  

For a typical product/eng. org, my recommendation is that each team's release plan is presented & reviewed at least monthly in a meeting that includes:
  - All Significant Leadership
    - CEO
    - All other executives (C-level, VP, etc)
    - Directors
  - All business functions
    - Sales
    - Support
    - Service (e.g. account management)
    - Product
    - Engineering
    - Accounting
  - Any other stakeholders
  - Anyone else with need/interest
  - The product+engineering team who's plan is being reviewed

What happens at these meetings?
   - Hard realities are confronted
   - Unforseen things come to light
   - Holes are poked in the plans
   - Broad awareness (and, often, consensus) is achieved
   - Ideas surface
   - Decisions are made in real-time
   - The CEO makes decisions & breaks-ties (keeps it productive)


## Related Links

  - [Agile Product Ownership in a Nutshell](https://www.youtube.com/watch?v=502ILHjX9EE) - Henrik Kniberg
  - [AppFoo.io](https://appfoo.io) 

`.trim()}</Markdown>
  </div>
}


let actuallyLead = {
  parent: leadership,
  "name": "Actually Lead",
  "subtitle": "You don't have to be a bully, but you do have to lead.",
  body: <div>
    <h2>Consensus &amp; Collaboration Requires Leadership &amp; Cultivation</h2>

    <p>We strive for collaboration &amp; meritocracy, and to lead with consensus.  But, those qualities rarely exist in a leadership & guidance vacuum.  As leaders, you play an important facilitator/policing role that requires you to:</p>

    <ol>
      <li>Actively drive the group toward concrete decisions/conclusions (don't let the conversation turn round and round, meeting after meeting)</li>
      <li>Make sure the group's decisions/conclusions are clear</li>
      <li>Measure &amp; Monitor the outcomes (i.e. follow-up &amp; enforce!)</li>
    </ol>

    <h2>Leaders Establish Clarity (Measuring &amp; Monitoring Outcomes)</h2>

    <p>As a leader, you are required to make sure that things get done once a decision is made.  This requires that you make sure that all parties involved have a clear understanding of what they have to do in order to comply-with/meet an objective/decision.</p>

    <p>Namely, the following must be clear to all involved:</p>
    <ol>
      <li>The Objective/Goal/Requirement</li>
      <li>How it's measured</li>
      <li>Who's Responsible</li>
    </ol>
  </div>
}

let theDirectPath = {
  "name": "the fast track",
  "subtitle": "The Direct Path to Becoming a Software Engineer",
  body: <div>

    <h3>Requirements</h3>

    To follow this path, you must:

    <ul>
      <li>be self-motivated</li>
      <li>know how to learn</li>
      <li>create time to learn</li>
      <li>have a computer and an internet connection</li>
      <li>choose a project to work on</li>
      <li>have a mentor</li>
      <li>hang-out with software engineers</li>
    </ul>

    <h3>The Objective</h3>

    <p>Our objective is to get you to one of the following outcomes:
      <ol>
        <li>you're able to get a job at a good company as an entry-level software engineer, or</li>
        <li>you've realized that being a software engineer is not for you, without wasting a lot of money</li>
      </ol>
    </p>

    At the end, you'll:

    <ul>
      <li>have a public project on github that showcases your ability to do end-to-end software engineering</li>
      <li>be able to talk like a software engineer</li>
      <li>have end-to-end experience on a software project</li>
      <li>know the tools that software engineers use</li>
      <li>be able to touch-type code at 40+wpm</li>
      <li>have a basic conceptual & theoretical foundation on which to operate</li>
      <li>have industry connections that may help in your job search</li>
    </ul>


    <h4>Step 1 - Choose a Project, a 'Stack' and find a Support System</h4>
    <ul>
      <li><em>Choose a project</em>  - one where you can use the same language on the front-end and back-end. I recommend one of the following:
        <ul>
          <li>For a web-based project: javascript/node</li>
          <li>For a mobile app project: android/kotlin</li>
        </ul>
      </li>

      <li><em>Choose a mentor</em> - an existing, senior-level engineer who is willing to spend time with you on a weekly basis to review your work and provide guidance</li>
      <li><em>Make connections</em> - with local & global groups (meetups, etc)</li>
      <li><em>Get some reading material</em> - a copy of <a href="https://www.amazon.com/Code-Language-Computer-Hardware-Software/dp/0735611319">Code: The Hidden Language of Computer Hardware and Software</a></li>
    </ul>

    <h4>Step 2 - "Hello World"</h4>

    Theory:
    <ul>
      <li>Read and understand the first 3 chapters of <a href="https://www.amazon.com/Code-Language-Computer-Hardware-Software/dp/0735611319">Code</a>
      </li>
    </ul>

    Practice:
    <ul>
      <li>Acquire basic familiarity with the tools of the trade
        <ul>
          <li>git</li>
          <li>github</li>
          <li>IDE/Editor</li>
          <li>Runtime environment</li>
        </ul>
      </li>
      <li>
        Get your app working end-to-end
        <ul>
          <li>"Hello world" backend</li>
          <li>"Hello world" frontend</li>
        </ul>
      </li>
    </ul>

    <hr />

    This is a work-in-progress; I've not yet written down the rest of the steps.  But it essentially consists of iterating on your project in small, bite-sized chunks, gradually adding-in databases, deployments, project management tools, etc.  I plan to get around to writing the rest down someday, but, in the meantime: your mentor should be able to help you with this.
  </div>
}

let padZeros = (v:any):string => {
  let r = `${v}`
  const zerosNeeded = Math.max(0, 2 - r.length)

  for(let x=0; x<zerosNeeded; x++){
    r = "0" + r
  }

  return r
}
let now = new Date()
let resume = {
  ...experience,
  name: "Stu Penrose",
  title: `spenrose-resume-${now.getFullYear() - 2000}${padZeros(now.getMonth())}${padZeros(now.getDay())}${padZeros(now.getHours())}${padZeros(now.getMinutes())}`
}

let tabs: Record<string, PageStuff> = {
  nurture: nurture,
  leadership: leadership,
  home: home,
  process: process,
  experience: experience,
  resume: resume,
  "teams-and-architecture": teamsAndArchitecture,
  talks: talks,
  presentations: talks,
  "reinventing-the-wheel": reinventingTheWheel,
  "date-and-time": dateAndTime,
  agility: agility,
  planning: planning,
  "actually-lead": actuallyLead,
  design: design,
  "getting-started": gettingStarted,
  "direct-to-engineer": theDirectPath,
  "time-cost-scope-quality": timeCostScopeQuality
}

export default tabs