import * as R from 'ramda';

export interface TechnologyThing {
    name:string
    status?:string,
    notes?:string,
    url?:string,
    tags?:string[],
}
export type TechRadar = Record<string, TechnologyThing[]>


interface Accum {
    categories:TechRadar,
    _currentCategory:string|undefined
    _currentItem:TechnologyThing|undefined
}


export const parseRadar = (text:string):TechRadar => {
    let thing = R.pipe(
        R.split('\n'),
        R.reduce((acc:Accum, line:string) => {
            const category = line.match(/^\[(.+)\]/);
            if (category) {
                acc.categories[category[1]] = [];
                acc._currentCategory = category[1];
            }
            else {
                const item = line.match(/^\w+/);
                if (item) {
                    const i:TechnologyThing = {name:line}
                    acc._currentItem = i;
                    acc.categories[acc._currentCategory ?? "default"].push(i);
                }else{
                    let trimmedLine = R.trim(line)
    
                    let i = acc._currentItem
                    if(i){
    
                        if (trimmedLine.indexOf('status:') === 0) {
                            i.status = R.trim(R.trim(line).replace('status:', ''));
                        }else if (trimmedLine.indexOf('notes:') === 0) {
                            i.notes = R.trim(R.trim(line).replace('notes:', ''));
                        }else if (trimmedLine.indexOf('url:') === 0) {
                            i.url = R.trim(R.trim(line).replace('url:', ''));
                        }else if (trimmedLine.indexOf('tags:') === 0) {
                            const tags = R.trim(R.trim(line).replace('tags:', '')).split(',');
                            i.tags = R.map(R.trim, tags);
                        }
                    }
    
                }
                
            }
            return acc;
        }, {categories:{}, _currentCategory:undefined, _currentItem:undefined}),
        R.omit(['_currentCategory', '_currentItem'])
    
    );

    return thing(text).categories
}

const splitIfExists = (str:string|undefined):string[] => str ? str.split(',') : [];

function onlyUnique<T>(value:T, index:number, array:T[]):boolean {
    return array.indexOf(value) === index;
  }
  

export const getAllTags = (v:TechRadar):string[]=> {
    return Object.values(v)
        .flatMap(i=>i)
        .flatMap(i=>i.tags)
        .flatMap(splitIfExists)
        .filter(onlyUnique)
        .sort()
}
    

    

