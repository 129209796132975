import { Typography } from "@mui/material"
import Markdown from "marked-react"
import { CSSProperties } from "react"

const max = (items:number[]):number|undefined => {
    let result:number|undefined

    items.forEach(n=>{
        if(typeof result == "undefined" || result < n) {
            result = n
        }
    })

    return result
}

const min = (items:number[]):number|undefined => {
    let result:number|undefined

    items.forEach(n=>{
        if(typeof result == "undefined" || result > n) {
            result = n
        }
    })

    return result
}


const takeWhile = (str:string, fn:(prefix:string, last:string)=>boolean):string => {
    let result = ""

    // console.log(`stripMarkdownIndent-takeWhile.length:${str.length}`)
    for(let x=0;x<str.length;x++){
        // console.log(`stripMarkdownIndent-takeWhile:${x}`)
        const next = str.charAt(x)
        const prefix = `${result}${next}`
        if(!fn(prefix, next)){
            return result
        }else{
            result = prefix
        }
    }

    return result
}

const stripMarkdownIndent = (raw:string):string => {
    console.log(`stripMarkdownIndent-before:${raw}`)
    const content = raw
    const lines = content.split("\n")
    console.log(`stripMarkdownIndent-lines:${lines}`)
    const prefixLength = min(lines.flatMap(line=>{  

        if(line.trim().length == 0){
            return []
        }else{

            console.log(`stripMarkdownIndent-line:${line}`)
            const n =  takeWhile(line, (p, n)=> {
                // console.log("stripMarkdownIndent-char", n, "vs", ' ')
                return n == ' '
            }).length
            console.log(`prefix is ${n} of ${line}`)
            return [n]
        }
    })) || 0

    console.log(`stripMarkdownIndent-prefixLength:${prefixLength}`)

    const trimmedLines = lines.map(line => line.substring(prefixLength))
    const trimmedContent = trimmedLines.join("\r\n")
    console.log(`stripMarkdownIndent-trimmedContent:${trimmedContent}`)
    return trimmedContent
}
const allHeadings:CSSProperties = {    
    fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Lucida Grande,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,'.SFNSDisplay-Regular',Heiti SC,Microsoft Yahei;",
    color: "#4d4d4d",
}
const headingProps:Record<number, CSSProperties> = {
    0:allHeadings,
    1:{... allHeadings, fontSize:"1.5em"},
    2:{... allHeadings, fontSize:"1.5em"},
}

export default (props:{children:string})=>{
    
    return (<Markdown
    renderer={{
        heading:(text, level)=>{
            return <Typography style={headingProps[level] ?? allHeadings}>{text}</Typography>
        }
    }}
    >{stripMarkdownIndent(props.children)}</Markdown>)
}
