
// Infrastructure
// Chef, Heroku, JBoss, Jakarta EE, Tomcat
// Language
// Library
// Active MQ, Akka, Ant, Core Location, D3.js, ESLint, Hibernate ORM, JUnit, Jasmine, JavaFX, Jest, Kibana, MapKit, Moment.js, OpenCV, OpenStreetMap, Protocol Buffers, Ramda, Redux, RequireJS, RxJava, Sinatra, SinonJS, Spock, Underscore, Web3, jQuery
// Markup
// DHTML, HTML, HTML5, Handlebars, MustacheJS, PL/SQL, SQL, Transact SQL, UML, WSDL, XHTML, XML, XSLT, YAML
// Stack
// BAMP, BAPP, ELK, FAMP, JOLT, LAMJ, LAMP, NAMP, NAPP
// Standards
// AJAX, APIs, DHCP, DNS, EJB, ES, ES5, ES6, GraphQL, HTTP, HTTP/2, J2EE, JSF, JSON, JWT, Keberos, OAuth, ORM, POSIX, REST, SAML, SFTP, SOA, SOAP, SSO, TCP/IP, TCP/IP Networking, WebSocket, XMPP
// Stylesheets
// CSS, CSS3, Flex, Flexbox, Foundation, LESS, Material Design, SASS, styled-components



let rawText = `
[Network APIs]
Auth0
GitHub API
GoogleMaps API
Paypal API
Slack API
Twilio

[Standards/Protocols]
AJAX
APIs
DHCP
DNS
ES
ES6
GraphQL
HTTP
HTTP/2
JSON
JWT
OAuth
REST
TCP/IP
WebSocket
TCP/IP Suite
Hessian
RMI
Corba
SMB
SSL/TLS
AFP
NFS
LDAP
SMTP
SQL
IMAP
X11
RFB
Kerberos

[Operating Systems]
Android
    tags: mobile, linux, jvm
BSD
    tags: unix
FreeBSD
    tags: unix
Linux
    tags: linux
SELinux
    tags: linux
Debian
    tags: linux
Ubuntu
    tags: linux
Gobolinux
    url: https://gobolinux.org/hall_of_fame.html
    notes: I was user #28 (00011100) & the original contributor of the Gnome Desktop package
    tags: linux
Unix
    tags: unix
Windows
iOS
    tags: mobile, unix
macOS
macOSX
    tags: unix
NetBSD
    tags: unix

[Methodology]
Agile
Kanban
Lean
OOD
Scrum
SCRUMBan
XP

[Disciplines]
3D Printing
AdTech
Administration
Algorithm Development
Analytics
Android Design
Application Security
Architecture
Artificial Intelligence
Augmented Reality
Automated Testing
Automation
Big Data
Business Intelligence
CI/CD
ChatBots
Cloud Architecture
Cloud Solutions
Cluster Computing
Configuration Management
Cryptography
Cyber Security
Data Modeling
Database Administrator
Database Architecture
Database Design
Debugging
Design
Design systems
Design thinking
Desktop Applications
Digital Marketing
Distributed Systems
Embedded Systems
Encryption
Engineering Management
Enterprise Networking
Ethical Hacker
Firewalls
Geographic Information Systems
Growth Hacking
Hardware
Human Computer Interaction Design
Hybrid Apps
Icon Design
Information Architechture
Information Security
Integration Testing
Interactive Design
Localization
Logo Design
MBA
Machine Learning
Management
Microservices
Mobile Security
Network Admin
Network Engineering
Network Security
Networking
OOP
Open Source Contributor
P2P networking
PCI Compliance
Pair Programming
Penetration Testing
Product Designer
Product Development
Prototyping
Public Key Infrastructure
QA
QA Automation
QA Professional
Regular Expressions
Responsive Design
Robotics
Routing
Routing Algorithms
SPA
Scrum Master
Serverless
Technical Writing
UI/UX
Unit Testing
Usability Testing
User Research
Web Components
Web Services
iOS Design


[Techniques/Patterns]
SOLID 
OOD 
BDD
cake pattern
    status: outoffavor
    notes:

XML-RPC/SOAP
    status: outoffavor
    tags:api

REST (RMM Level 1)
    status: outoffavor
    tags: REST, api

REST (RMM Level 2)
    status: accepted
    tags: REST, api

REST (RMM Level 3)
    status: outoffavor
    tags: REST, api
	notes:

JS Template Observer
    status: outoffavor
    tags: javascript

"NaN style" JS Components
    status: outoffavor
    tags: javascript
    notes:

single responsibility principle
    status: preferred

"mockist" testing
    status: accepted
    tags: test

fakes over mocks
    status: accepted
    tags: test

configuration-as-a-service
    status: accepted

object-oriented programming
    status: accepted
    tags: oop

functional
    status: accepted

machine learning
    status: accepted

promises over callbacks
    status: accepted
    tags: javascript

branch-by-abstraction
    status: accepted

feature-flags
    status: accepted

async-io
    status: accepted

rdbms
    status: accepted

standalone, self-contained apps
    status: preferred
    tags: jvm

JEE containers
    status: outoffavor
    tags: jvm

design-by-contract
    status: accepted

automation
    status: preferred

continuous-delivery
    status: preferred
    tags: build

TDD (test-first)
    status: preferred
    tags: test

dependency injection over service locator
    status: preferred

pair programming
    status: preferred

one unit testing library per application
    status: preferred

[Languages and Frameworks/Libraries]
Rust
    tags: native
Kotlin Native/Multiplatform
    tags: native
Swing
    tags: jvm
Log4j
    tags: jvm

Material Design
    status:
    tags: web, android, frontend

HTML
    status:
    tags: web, html

HTML5
    status:
    tags: web, html

UIKit
    status:
    tags: ios, mobile, macosx

JDBC
    status:
    tags:SQL, rdbms, relational, jvm, data-tier

Swagger
	status: accepted
	tags: api, REST
	notes:

RestSpec
	status: accepted
	tags: api, REST
	notes:

jqplot
   status: outoffavor
   tags: javascript, visualization

amcharts
   status: preferred
   tags: javascript, visualization

spark
   status: accepted
   tags: hadoop, data-tier, jvm, scala
   notes:

ramda
  status: accepted
  tags: javascript

redux
  status: preferred
  tags: javascript

graphql
  status: preferred
  tags: api

XML
    status: outoffavor
    tags: api, REST

JSON 
    status: preferred
    tags: api, REST


mustache
    status: outoffavor
    tags: javascript

squire
    status: outoffavor
    tags: javascript, test

play
    status: outoffavor
    tags: jvm

jsp
    status: outoffavor
    tags: templating, jvm

ojb
    status: outoffavor
    tags: jvm, data-tier

Adobe Flex
    status: outoffavor

pl/sql
    status: outoffavor
    tags: languages, data-tier

joyride js
    status: outoffavor
    tags: javascript

hibernate-search
    status: outoffavor
    tags: jvm, data-tier

raw lucene
    status: outoffavor
    tags: jvm, data-tier

struts
    status: outoffavor
    tags: jvm

xstream
    status: outoffavor
    tags: jvm

xml
    status: outoffavor

grails
    status: outoffavor
    tags: languages, jvm, groovy

PHP
    status:
    tags: languages, lamp

ruby
    status: outoffavor
    tags: languages

haskell
    status: accepted
    tags: languages

react
    status: preferred
    tags: javascript
    notes:

clojure
    status: accepted
    tags: jvm, languages
    notes:

intro.js
    status: accepted
    tags: javascript

weka
    status: accepted
    tags: machine learning

Backbone
    tags: web 

underscore
    status: outoffavor
    tags: javascript

bootstrap-css
    status: accepted
    tags: javascript

handlebars
    status: outoffavor
    tags: templating, javascript

java6
    status: outoffavor
    tags: jvm, languages
    notes:

backbon
    tags: javascript

sinon
    status: accepted
    tags: javascript, test

restclient-js
    status: accepted
    tags: javascript, test

httpobjects
    status: accepted
    notes: I am the original and primary author of this open-source library, an http abstraction layer used to power billions of dollars of online transactions at multiple companies.
    url: http://httpobjects.org
    tags: jvm, web, http

JPA
   status:
   tags: jvm, data-tier

hibernate
    status: accepted
    tags: jvm, data-tier

velocity
    tags: templating, jvm

akka
    status: accepted
    tags: jvm

scala
    status: accepted
    tags: jvm, languages

javascript
    status: accepted
    tags: javascript, languages

c
    status:
    tags: languages

c++
    status:
    tags: languages

Objective-C
    status:
    tags: languages, ios, mobile, macosx

Swift
    status:
    tags: languages, ios, mobile, macosx

bash
    status:
    tags: languages, scripting, unix

ActionScript
    status:
    tags: languages, web
Assembly
    tags: languages

basic
    status:
    tags: languages

groovy
    status: outoffavor
    tags: test, groovy, jvm, languages

spock
    status: outoffavor
    tags: test, groovy, jvm

jmock
    status: accepted
    tags: test, jvm

powermock
    status: outoffavor
    tags: test, jvm

mockito
    status: accepted
    tags: test, jvm

easymock
    status: accepted
    tags: test, jvm

jetty (as a library)
    status: accepted
    tags: jvm

mahout
    status: accepted
    tags: jvm, machine learning

coffeescript
    status: outoffavor
    tags: javascript, languages

yammer metrics
    status: accepted
    tags: jvm

jsoup
    status: accepted
    tags: jvm

eh-cache
    status: accepted
    tags: jvm

java7
    status: outoffavor
    tags: jvm, languages
    notes:

java8
    status: outoffavor
    tags: jvm, languages
    notes:

java11
    status: accepted
    tags: jvm, languages

java15
    status: accepted
    tags: jvm, languages
    notes:

hamcrest-matchers
    status: accepted
    tags: test, jvm

junit
    status: accepted
    tags: test, jvm

scalatest
    status: accepted
    tags: test, jvm

jackson
    status: accepted
    tags: jvm

lift-json
    status: accepted
    tags: jvm

jquery-ui
    status: outoffavor

springmvc
    status: accepted
    tags: jvm

spring (dependency-injection)
    status: accepted
    tags: jvm

spring-security
    status: accepted
    tags: jvm

python 
    status: accepted
    tags: languages

jquery
    status: outoffavor
    tags: javascript
    note: We believe all uses of jquery can be handled natively or with other accepted libs

require
    status: outoffavor
    tags: javascript, build

styled-components
    status: accepted
    tags: javascript, css

Kotlin
    status: accepted
    tags: languages, kotlin

Arrow (Functional Library for Kotlin)
    status: accepted
    tags: library, kotlin, jvm

Typescript
    status: accepted
    tags: languages

AWS CDK for Cloudformation
    status: experimental
    tags: javascript, typescript, aws, cloudformation
    notes:

[Tools]

AWS
Active Directory
Android SDK
Ansible
ArcGIS
Bitbucket
Eclipse
GIMP
GSuite
GitHub
GitLab
Google Play
Illustrator
ImageMagick
JIRA
Jmeter
Kafka
MS Office
PhantomJS
RabbitMQ
Splunk
Tableau
VMware
Xcode
vim

yarn
    status:
    tags: javascript, tools, build

gradle
    status:
    tags: jvm, tools, build

babel
    status:
    tags: javascript, tools, build

qunit
    status: accepted
    tags: javascript, test

jest
    status: accepted
    tags: javascript, test

webpack
    status: accepted
    tags: javascript, bundler


gitlab
    status: accepted
    tags: scm

gitblit
    status: outoffavor
    tags: scm

splunk
    status: accepted

maven2
    status: outoffavor
    tags: build

perforce
    status: outoffavor
    tags: scm

stash
    status: outoffavor
    tags: scm

gulp
    status: accepted
    tags: javascript, build

bower
    status: outoffavor
    tags: javascript, build
    notes:

selenium
    status: outoffavor
    tags: test

docker
    status: accepted

vagrant
    status: accepted

github 
    status: 
    tags: scm

virtualBox
    status: accepted

kibana
    status: accepted

ansible
    status: accepted

ganglia
    status: accepted
    tags: data-tier

grafana
    status: 
    tags: data-tier, infrastructure, metrics, logging

loki
    status: 
    tags: data-tier, infrastructure, logging

prometheus
    status: 
    tags: data-tier, infrastructure, metrics

jenkins
    status: preferred
    tags: build

qunit-mojo
    status: preferred
    tags: test, javascript, build

jshint
    status: outoffavor
    tags: test, javascript, build

eslint
    status: preferred
    tags: lint, javascript

nexus
    status: preferred

maven3
    status: preferred
    tags: build

supervisor
    status: preferred

svn
    status: outoffavor
    tags: scm


visual-source-safe
    status: outoffavor
    tags: scm

git
    status: preferred
    tags: scm

[Platforms (and dbs)]
Datomic
    
Voldemort
    status:
    tags: nosql, data-tier

BerkeleyDB
    status:
    tags: nosql, embedded, mobile, data-tier

Amazon IAM
    tags: cloud, aws, infrastructure
    
Amazon VPC
    status:
    tags: cloud, aws, infrastructure

Amazon EC2
    status:
    tags: cloud, aws, vps

Amazon ECS
    status:
    tags: cloud, aws, vps

Amazon ELB
    status:
    tags: cloud, aws, infrastructure

Amazon Elastic MapReduce
    status:
    tags: cloud, aws, data-lake, data-tier, map-reduce, hadoop, spark

Amazon Kinesis
    status:
    tags: cloud, aws, data-tier, data-stream

Amazon Lambda
    status:
    tags: cloud, aws, serverless

Amazon RDS
    status:
    tags: cloud, aws, rdbms, data-tier

Amazon DMS
    status:
    tags: cloud, aws, rdbms, nosql, data-tier

Amazon S3
    status:
    tags: cloud, aws, nosql, data-tier

DigitalOcean
    status:
    tags: cloud, vps
KVM
    status:
    tags: vps

Rackspace
    status:
    tags: cloud, vps
Linode
    status:
    tags: cloud, vps

kafka
   status: accepted
   tags: data-tier, api, data-stream
   notes:

kafka-rx
   status: outoffavor
   tags: data-tier, reactive
   notes:

rabbitmq
    status: experimental
    tags: message broker, message queue, asynchronous messaging
    notes:

solr
    status: outoffavor
    tags: full-text search

oracle text (aka context)
    status: outoffavor
    tags: full-text search

PostGIS
    status:
    tags: sql, rdbms, data-tier, geo, gis

PostgreSQL
    status:
    tags: sql, rdbms, data-tier

mysql/mariadb
    status: outoffavor
    tags: rdbms, sql, data-tier

hadoop map-reduce v1
    status: outoffavor
    tags: jvm, data-tier

appworx
    status: outoffavor

J2EE (incl. EJB, JNDI, JMS, ServletAPI)
    status: outoffavor
    tags: jvm

activemq
    status: outoffavor
    tags: jvm

fiorano
    status: outoffavor
    tags: jvm

resin
    status: outoffavor
    tags: jvm

hadoop map-reduce v2 ("yarn")
    status: outoffavor
    tags: data-tier
    notes:

oracle
    status: accepted
    tags: data-tier

Microsoft SQL Server
    tags: data-tier, rdbms, sql

mongo
    status: accepted
    tags: data-tier

Dynamo DB
    status:
    tags: aws, nosql, data-tier

cassandra
    status: accepted
    tags: data-tier

hdfs
    status: accepted
    tags: data-tier

hadoop
    status: outoffavor
    tags: data-tier
    notes:

flume
    status: outoffavor
    tags: data-tier

kestrel
    status: outoffavor

node.js
    status: accepted
    tags: javascript

zookeeper
    status: accepted

elastic search
    status: preferred
    tags:data-tier,full-text search

jvm 
    status: preferred
    tags: jvm, languages
`


export default rawText