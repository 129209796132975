import './devstew.css'
import tabs, { PageStuff } from './content'
import React from 'react'
import _ from 'underscore'
import { useNavigate, useParams } from 'react-router-dom'

let fourOFour:PageStuff = {
  name: "404",
  subtitle: "Well, it seems you've requested a page that I don't have right now.",
  body: <div>
    <div>
      {/* just here so it shows empty */}
    </div>
  </div>
}

export default () => {
  const nav = useNavigate()
  const params = useParams()
  const pageName: string = params.pageName ?? "home"

  console.log("Showing tabName", pageName)
  
  const setTab = (path: string) => {
    nav(path)
  }

  let handleLink: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    let path = (event.target as any).getAttribute("href") ?? "/"
    event.preventDefault()
    window.history.pushState(undefined, "unused", path)
    setTab(path)
  }


  console.log("Showing ", pageName)

  let tab = tabs[pageName] ?? fourOFour
  document.title = tab.title ?? ("Stu" + (pageName == "home" ? "" : "/" + pageName))
  let name = tab.name ?? pageName
  let displayName = tab.displayName ? tab.displayName() : name
  let parentName = tab.parent?.name
  let subtitle = tab.subtitle ?? "??"
  let body = tab.body ?? "??"
  let footer = tab.footer

  let parentPath = parentName ? <span className='title-path'><a href={"/" + parentName}>{parentName}</a>: </span> : ""

  let topLevel = [
    tabs.experience,
    tabs.leadership,
    tabs.agility,
    tabs.design,
    tabs.nurture
  ]

  let menuEntries = _.map(topLevel, (tab) => {
    const path = "/" + tab.name
    const selectedClass = tab.name == pageName ? "menu-entry-selected" : ""
    const classList = ["menu-entry", selectedClass]
    return <div className={classList.join(" ")}><a onClick={handleLink} href={path}>{tab.name}</a></div>
  })

  return <>

    <div className="top-menu">{menuEntries}</div>
    <div className="header">
      <div className="title-band">{parentPath}{displayName}<div className="magic-spacer">&nbsp;</div></div>
      <div className="photo-frame">
        <div className="photo">
          <a onClick={handleLink} href="/">
            <img src="/IMG_5793-256.png" />
          </a>
        </div>
      </div>
    </div>

    <div className="menu">{menuEntries}</div>

    <span className="subtitle">{subtitle}</span>


    <div className="bio">
      {body}
    </div>

    <div>
      {footer}
    </div>

    <span className="horizontal-spring">This is a really long long.  It has many parts to it.This is a really long long.  It has many parts to it.This is a really long long.  It has many parts to it.This is a really long long.  It has many parts to it.This is a really long long.  It has many parts to it.</span>

  </>;
}
